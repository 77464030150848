/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
body {
  background-color: #212121;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: white;
}
body a {
  text-decoration: none;
  transition: all 0.25s ease;
}
body a, body a:visited, body a:focus, body a:active {
  color: white;
  font-weight: 700;
}
body a:hover {
  color: #00d5c4;
}
body section:not(.fullwidth),
body footer {
  margin: 0 auto;
  padding: 1.875rem 5%;
  max-width: 1440px;
}
@media only screen and (min-width: 480px) {
  body section:not(.fullwidth),
body footer {
    padding: 1.875rem 1.5%;
  }
}
@media only screen and (min-width: 1024px) {
  body section:not(.fullwidth),
body footer {
    padding: 1.875rem 3.125rem;
  }
}
body main {
  display: flex;
  flex-direction: column;
}
body main h1#page-heading {
  order: 1;
  margin: 0 auto;
  padding: 1.875rem 5%;
  max-width: 1440px;
  padding-bottom: 0.9375rem !important;
  background: linear-gradient(90deg, #00a295, #e70c80);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  line-height: 1.25;
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}
@media only screen and (min-width: 480px) {
  body main h1#page-heading {
    padding: 1.875rem 1.5%;
  }
}
@media only screen and (min-width: 1024px) {
  body main h1#page-heading {
    padding: 1.875rem 3.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  body main h1#page-heading {
    order: 2;
    margin-top: 3.75rem;
    line-height: 1;
    font-size: 5rem;
  }
}
body main section#video-player {
  order: 2;
  padding-top: 0;
  padding-bottom: 0;
  background-color: black;
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  body main section#video-player {
    order: 1;
  }
}
body main section#video-player #video-player-cont {
  margin: 0 auto;
  padding: 1.875rem 5%;
  max-width: 1440px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@media only screen and (min-width: 480px) {
  body main section#video-player #video-player-cont {
    padding: 1.875rem 1.5%;
  }
}
@media only screen and (min-width: 1024px) {
  body main section#video-player #video-player-cont {
    padding: 1.875rem 3.125rem;
  }
}
body main section#video-player #video-player-cont video {
  display: block;
  aspect-ratio: 16/9;
}
body main section#video-library {
  order: 3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
body main section#video-library .video-card {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  position: relative;
  background-color: #616161;
  transition: all 0.25s ease;
  margin-bottom: 30px;
}
@media only screen and (min-width: 480px) {
  body main section#video-library .video-card {
    flex-basis: 49%;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
  body main section#video-library .video-card {
    flex-basis: 32%;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
  body main section#video-library .video-card:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
    transform: scale(1.085) rotate(-1.25deg);
  }
}
@media only screen and (min-width: 1024px) {
  body main section#video-library .video-card:hover .video-card-img .video-duration {
    opacity: 1;
    transition: opacity 0.25s ease;
  }
}
body main section#video-library .video-card.video-card-playing::after {
  content: none;
}
@media only screen and (min-width: 1024px) {
  body main section#video-library .video-card.video-card-playing::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 7px;
    height: 100%;
    background-color: #00a295;
  }
}
body main section#video-library .video-card.video-card-playing .video-info {
  position: relative;
}
body main section#video-library .video-card.video-card-playing .video-info::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 7px;
  height: 100%;
  background-color: #00a295;
}
@media only screen and (min-width: 1024px) {
  body main section#video-library .video-card.video-card-playing .video-info::after {
    content: none;
  }
}
body main section#video-library .video-card .video-card-img {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  position: relative;
  box-shadow: none;
  height: 100%;
  background-color: black;
  cursor: pointer;
}
body main section#video-library .video-card .video-card-img img {
  width: 100%;
  height: 100%;
}
body main section#video-library .video-card .video-card-img .video-duration {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.125em;
  padding: 0.3125rem;
  line-height: 1;
  font-size: 0.875rem;
}
@media only screen and (min-width: 1024px) {
  body main section#video-library .video-card .video-card-img .video-duration {
    opacity: 0;
  }
}
body main section#video-library .video-card .video-info {
  padding: 0.9375rem;
}
body footer {
  text-align: center;
  font-size: 0.875rem;
}