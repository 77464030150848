$bg-color: darken(#474747, 15%);
$mp-blue: #337ab7;
$gray: #eee;
$text-color: white;
$link-color: white;
$link-hover-color: lighten(#00a295, 10%);
$card-bg-color: lighten(#474747, 10%);
$video-playing-border: #00a295;

@mixin page-width-padding {
  margin: 0 auto;
  padding: 1.875rem 5%;
  max-width: 1440px;

  @media only screen and (min-width: 480px) {
    padding: 1.875rem 1.5%;
  }

  @media only screen and (min-width: 1024px) {
    padding: 1.875rem 3.125rem;
  }
}

@mixin video-playing-bar {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 7px;
  height: 100%;
  background-color: $video-playing-border;
}

// Reset by Piccalilli
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
// html:focus-within {
//   scroll-behavior: smooth;
// }

/* Set core body defaults */
body {
  // min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  background-color: $bg-color;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: $text-color;

  a {
    text-decoration: none;
    transition: all 0.25s ease;

    &,
    &:visited,
    &:focus,
    &:active {
      color: $link-color;
      font-weight: 700;
    }

    &:hover {
      color: $link-hover-color;
    }
  }

  section:not(.fullwidth),
  footer {
    @include page-width-padding();
  }

  main {
    display: flex;
    flex-direction: column;

    h1#page-heading {
      order: 1;
      @include page-width-padding();
      padding-bottom: 0.9375rem !important;
      background: linear-gradient(90deg, #00a295, #e70c80);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      line-height: 1.25;
      font-size: 2.5rem;
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;

      @media only screen and (min-width: 1024px) {
        order: 2;
        margin-top: 3.75rem;
        line-height: 1;
        font-size: 5rem;
      }
    }

    section {
      &#video-player {
        order: 2;
        padding-top: 0;
        padding-bottom: 0;
        background-color: black;
        text-align: center;

        @media only screen and (min-width: 1024px) {
          order: 1;
        }

        #video-player-cont {
          @include page-width-padding();
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          // @supports not (aspect-ratio: 16/9) {
          // }

          video {
            display: block; // Prevents empty space at the bottom
            aspect-ratio: 16/9; // Sets the same height for all players in case of videos with different aspect ratios

            // @supports not (aspect-ratio: 16/9) {
            // }
          }
        }
      }

      &#video-library {
        order: 3;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .video-card {
          display: flex;
          flex-direction: column;
          flex-basis: 100%; // 1 card per row
          position: relative;
          // min-height: 182px;
          background-color: $card-bg-color;
          transition: all 0.25s ease;
          margin-bottom: 30px;

          @media only screen and (min-width: 480px) {
            flex-basis: 49%; // 2 cards per row with remaining space used in between
            flex-direction: row;
          }

          @media only screen and (min-width: 1024px) {
            flex-basis: 32%; // 3 cards per row with remaining space used in between
            flex-direction: row;
          }

          &:hover {
            @media only screen and (min-width: 1024px) {
              box-shadow: 0 0 30px rgb(0 0 0 / 25%);
              transform: scale(1.085) rotate(-1.25deg);
            }

            .video-card-img {
              // &:not(.video-card-player) {
              //   &::before {
              //     opacity: 1;
              //   }
              // }

              .video-duration {
                @media only screen and (min-width: 1024px) {
                  opacity: 1;
                  transition: opacity 0.25s ease;
                }
              }
            }
          }

          &.video-card-playing {
            // Pseudo-element creates green bar
            &::after {
              content: none;

              @media only screen and (min-width: 1024px) {
                @include video-playing-bar();
              }
            }

            // .video-card-img {
            //   &:not(.video-card-player) {
            //     &::before {
            //       background-image: url('/img/play-button-playing-overlay.png');
            //     }
            //   }
            // }

            .video-info {
              position: relative;

              &::after {
                @include video-playing-bar();

                @media only screen and (min-width: 1024px) {
                  content: none;
                }
              }
            }
          }

          .video-card-img {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 40%;
            position: relative;
            box-shadow: none;
            height: 100%;
            // aspect-ratio: 1/1;
            background-color: black;
            cursor: pointer;

            &:not(.video-card-player) {
              // &::before {
              //   position: absolute;
              //   top: 0;
              //   left: 0;
              //   content: '';
              //   width: 100%;
              //   height: 100%;
              //   // opacity: .85;
              //   background-image: url('/img/play-button-overlay.png');
              //   background-size: 3.5em;
              //   background-repeat: no-repeat;
              //   background-position: center center;
              //   background-color: transparent;
              //   z-index: 100;
              //   transition: all .5s ease;
              //   pointer-events: none;
              // }
            }

            img {
              width: 100%;
              height: 100%;
            }

            .video-duration {
              position: absolute;
              bottom: 5px;
              left: 5px;
              background-color: rgb(0 0 0 / 50%);
              border-radius: 0.125em;
              padding: 0.3125rem;
              line-height: 1;
              font-size: 0.875rem;

              @media only screen and (min-width: 1024px) {
                opacity: 0;
              }
            }
          }

          .video-info {
            padding: 0.9375rem;
          }
        }
      }
    }
  }

  footer {
    text-align: center;
    font-size: 0.875rem;
  }
}
